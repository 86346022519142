const CSSAcdn = class {
	constructor({ switchClass = 'open' } = {}) {
		this.switchClass = switchClass;
		this.cloneElms = [];
		this.idCount = 0;
	}
	open(elm) {
		if (elm.classList.contains(this.switchClass)) {
			return;
		}
		const _removeStyle = (e) => {
			if (e.propertyName !== 'height') {
				return;
			}
			this.removeStyle(e.target);
			e.target.removeEventListener('transitionend', _removeStyle);
		};
		elm.style.height = elm.scrollHeight + 'px';
		elm.classList.add(this.switchClass);
		elm.addEventListener('transitionend', _removeStyle);
	}
	close(elm) {
		if (!elm.classList.contains(this.switchClass)) {
			return;
		}
		elm.style.height = getComputedStyle(elm).getPropertyValue('height');
		setTimeout(() => {
			elm.style.height = '';
			elm.classList.remove(this.switchClass);
		}, 10);
	}
	toggle(elm) {
		if (elm.classList.contains(this.switchClass)) {
			this.close(elm);
		} else {
			this.open(elm);
		}
	}
	removeStyle(elm) {
		elm.style.height = '';
	}
};

const menuBtn = document.getElementById('menuBtn');
const menuCloseBtn = document.getElementById('menuClose');
const menuOverlay = document.getElementById('menuOverlay');
const sidebar = document.getElementById('sidebar');
const header = document.getElementById('header');
const contents = document.getElementById('contents');

const menuOpen = () => {
	sidebar.classList.add('_show');
	sidebar.classList.add('_close');
	header.classList.add('_menuClose');
	contents.classList.add('_menuClose');
	menuBtn.classList.add('_on');
	menuOverlay.classList.add('_show');
};

const menuClose = () => {
	sidebar.classList.remove('_show');
	sidebar.classList.remove('_close');
	header.classList.remove('_menuClose');
	contents.classList.remove('_menuClose');
	menuBtn.classList.remove('_on');
	menuOverlay.classList.remove('_show');
}

const menuToggle = () => {
	if (sidebar.classList.contains('_show')) {
		menuClose();
	} else {
		menuOpen();
	}
};

if (menuCloseBtn) {
	menuCloseBtn.addEventListener('click', menuClose, false);
}

if (menuOverlay) {
	menuOverlay.addEventListener('click', menuClose, false);
}

if (menuBtn) {
	menuBtn.addEventListener('click', menuToggle, false);
}

const userBtn = document.getElementById('userBtn');
const userMenu = document.getElementById('userMenu');

const userMenuToggle = () => {
	if (userMenu.classList.contains('_show')) {
		userMenu.classList.remove('_show');
	} else {
		userMenu.classList.add('_show');
	}
};

if (userBtn) {
	userBtn.addEventListener('click', userMenuToggle, false);
	document.body.addEventListener('click', (e) => {
		if ($(e.target).closest('#userMenu').length == 0 && $(e.target).closest('#userBtn').length == 0) {
			userMenu.classList.remove('_show');
		}
	}, false);
}

if ($('.js_calendar').length > 0) {
	$('.js_calendar').datepicker({
		format: "yyyy/mm/dd",
		language: "ja"
	});
}

if ($('.js_monthCalendar').length > 0) {
	$('.js_monthCalendar').datepicker({
		format: "yyyy/mm",
		minViewMode: 1,
		language: "ja"
	});
}

if ($('.js_timepicker').length > 0) {
	$('.js_timepicker').timepicker({
		timeFormat: 'H:mm',
		interval: 30,
		minTime: '0',
		maxTime: '11:30pm',
		defaultTime: '11',
		startTime: '00:00',
		dynamic: false,
		dropdown: true,
		scrollbar: true
	});
}

const modalBtns = document.querySelectorAll('.myModalBtn');
const modalOverlay = document.querySelectorAll('.myModalOverlay');
const modalCloseBtn = document.querySelectorAll('.myModalClose');
const modals = document.querySelectorAll('.myModal');
const closeModal = () => {
	for (let i = 0; i < modals.length; i++) {
		modals[i].classList.remove('show');
	}
	for (let i = 0; i < modalOverlay.length; i++) {
		modalOverlay[i].classList.remove('show');
	}
}
const openModal = (target) => {
	let _target;
	if(typeof target === 'string') {
		_target = document.querySelector(target);
	} else {
		_target = target;
	}
	_target.classList.add('show');
	_target.querySelector('.myModalOverlay').classList.add('show');
}
for (let i = 0; i < modalBtns.length; i++) {
	modalBtns[i].addEventListener('click', (e) => {
		const targetSelector = e.currentTarget.getAttribute('data-myModal-target');
		openModal(document.querySelector(targetSelector));
	}, false);
}
for (let i = 0; i < modalCloseBtn.length; i++) {
	modalCloseBtn[i].addEventListener('click', closeModal, false);
}
for (let i = 0; i < modalOverlay.length; i++) {
	modalOverlay[i].addEventListener('click', closeModal, false);
}

//アコーディオン
const acdn = new CSSAcdn();
const btns = document.querySelectorAll('.acdnBtn');

for (let i = 0; i < btns.length; i++) {
	btns[i].addEventListener('click', (e) => {
		let target = e.currentTarget.nextElementSibling;
		e.currentTarget.classList.toggle('on');
		acdn.toggle(target);
	}, false);
}

const messageModals = document.querySelectorAll('.messageModal');
const messageModalCloseBtns = document.querySelectorAll('.messageModalClose');
const messageModalOverlays = document.querySelectorAll('.messageModalOverlay');

const closeMessage = () => {
	for (let i = 0; i < messageModals.length; i++) {
		messageModals[i].classList.remove('show');
	}
	for (let i = 0; i < messageModalOverlays.length; i++) {
		messageModalOverlays[i].classList.remove('show');
	}
}

const showMessage = (selector) => {
	closeMessage();
	const target = document.querySelector(selector);
	if(!target) {
		return;
	}
	target.classList.add('show');
	target.querySelector('.messageModalOverlay').classList.add('show');
};

for (let i = 0; i < messageModalOverlays.length; i++) {
	messageModalOverlays[i].addEventListener('click', (e) => {
		closeMessage();
	}, false);
}

for (let i = 0; i < messageModalCloseBtns.length; i++) {
	messageModalCloseBtns[i].addEventListener('click', (e) => {
		closeMessage();
	}, false);
}

const scrollElm = ('scrollingElement' in document) ? document.scrollingElement : document.documentElement;
const smoothScrollElm = document.querySelectorAll('a[href^="#"]:not(.noscroll)');
const anchorScroll = (href, cb = null) => {
	let targetElm;
	if (typeof href === 'object') {
		targetElm = href;
	} else {
		targetElm = (href === '#') ? document.querySelector('html') : document.querySelector(href);
	}
	if (!targetElm) return;
	const startTime = Date.now();
	const scrollFrom = scrollElm.scrollTop;
	const duration = 300;
	const easing = function (t, b, c, d) { return c * (0.5 - Math.cos(t / d * Math.PI) / 2) + b; };
	const loop = () => {
		const targetPos = targetElm.getBoundingClientRect().top + scrollElm.scrollTop - scrollFrom;
		const currentTime = Date.now() - startTime;
		if (currentTime < duration) {
			scrollTo(0, easing(currentTime, scrollFrom, targetPos, duration));
			window.requestAnimationFrame(loop);
		} else {
			scrollTo(0, targetPos + scrollFrom);
		}
	};
	loop();
	if (typeof cb === 'function') {
		cb();
	}
}
for (let i = 0; i < smoothScrollElm.length; i++) {
	let elm = smoothScrollElm[i];
	elm.addEventListener('click', (e) => {
		e.preventDefault();
		anchorScroll(elm.getAttribute('href'));
	}, false);
}
